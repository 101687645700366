body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.section-title {
  text-align: center;
  font-weight: bold;
  color: $primary;
}
.mantine-Stepper-root {
  .mantine-Stepper-stepIcon {
    background-color: var(--mantine-color-gray-1);
    .mantine-Stepper-verticalSeparator {
      top: 1rem;
      height: auto;
    }
  }
  .mantine-Stepper-stepLabel {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .mantine-Stepper-stepDescription {
    font-size: 1.1rem;
    line-height: 1.4rem;
    color: #322f2f;
  }
}

.mantine-Stepper-step {
  margin-bottom: 20rem;
  margin: 10px;
}
.users {
  div > div {
    font-size: 1.2em;
  }
  h2 {
    width: fit-content;
    border-bottom: 4px solid $primary;
  }
}
.contact-section {
  .row {
    margin-top: 100px;
    z-index: 1;
  }
  .svg {
    width: 100%;
  }
}
.price-card {
  width: 400px;
  padding: 0;
  margin: 0;
  .card-body,
  .card-head {
    -webkit-box-shadow: -1px 1px 59px -15px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: -1px 1px 59px -15px rgba(0, 0, 0, 0.65);
    box-shadow: -1px 1px 59px -15px rgba(0, 0, 0, 0.65);
   // padding: 40px;
  }
  .card-head {
    text-align: center;
    border-radius: 0% 5% 0% 0%;
    background-color: #e8d5b5; // #77b2b4;
    div:nth-child(2) {
      color: #6e6565;
    }
  }
  .card-body {
    border-radius: 0% 0% 0% 10%;
    background: #f2ecff; //#7874A7
   .col-md-1{
    font-weight: 600;
     font-size: 1.4em;
    }
    

  }
}
@media (min-width: 769px){
  .users-section{
    background: url('../media/separator.png') 0% 0% / cover no-repeat;
    padding: 50px;
  }
  .card-body,
  .card-head {
    padding: 40px;
  }
  .benefits-cards{
    width: 75%;
  }

}
@media (max-width: 768px){
  .users-section{
    overflow: hidden;
    background: url('../media/movilSeparator.png') 7% center /cover no-repeat;
    padding: 30px;
  }
  .trades-individual{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0px;
    height: 90%;
    .row:first-child{
      margin-left: 60px;
      
    }
  }
  .users{
    width: 100%;
  }
  .card-head{
    padding: 25px;
  }
  .card-body{
    padding: 15px;
  }

}
