.footer{
    background: linear-gradient(90deg, #303f9f 5%, #1d3492 95%);
    margin-top: -5px;
    img{
        height: 15em;
    }
  }
.sub-footer{
    border-top: 1px solid rgb(174, 173, 173);
}
.footer-link{
    font-size: 16px;
    color: $secondary;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
@media (max-width: 768px) {
    .footer{
        img{
            height: 5em;
            width: 10em;
        }
        .footer-link{
            font-size: 1em;
        }
    }
}


