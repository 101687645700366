.navigation-bar {
  z-index: 1;
  position: fixed;
  color: #000;
  top: 0;
}

.navigation-bar a {
  text-decoration: none !important;
  white-space: nowrap;

  &.active {
    font-weight: 600;
  }
}
.link {
  cursor: pointer;
  font-weight: 600;
  font-size: 1.2em;
  &:hover {
    color: $secondary;
  }
}
.languages {
  span {
    cursor: pointer;
  }
  span.mx-2 {
    border-right: 1px solid black;
  }
}
