.title {
  font-size: 5em;
  margin: 100px 0;
  color: $primary;
}

@media (min-width: 769px) {
  .header {
    margin-bottom: 30px;
    background: url("../../media/bgWave.svg") 0% 0% / cover no-repeat;
    overflow: hidden;
    .introduction {
      width: 75%;
    }
    .col-md-6 {
      width: 60%;
    }
    p {
      width: 75%;
    }
  }
}
.bg-default {
  background: none;
}
@media (max-width: 768px) {
  .bg-default {
    background: linear-gradient(90deg, #7874a7 5%, #303f9f 95%);
  }

  .header {
    margin-bottom: 0px;
    overflow: hidden;
    .introduction {
      width: 100%;
      margin-top: 60px;
    }
    p {
      width: 90%;
    }
  }
}
